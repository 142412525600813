module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T2PLCXC","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.endorsement-board.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"https://www.endorsement-board.uk","short_name":"https://www.endorsement-board.uk","start_url":"/","background_color":"#ffffff","theme_color":"#225885","display":"standalone","icon":"src/assets/primary-logo.png","include_favicon":true,"cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":"962fb109b5c65678a56b636f9e2ff5f0"},
    }]
