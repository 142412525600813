// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-listing-js": () => import("./../../../src/templates/events-listing.js" /* webpackChunkName: "component---src-templates-events-listing-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-standard-js": () => import("./../../../src/templates/standard.js" /* webpackChunkName: "component---src-templates-standard-js" */)
}

